.itemStyle {
    width: 180;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
    overflow: hidden;
    word-wrap: break-word;
}

.menuStyle {
    position: relative;
    color: #133f26;
}

.itemMenuStyle {
    width: 180px;
    position: relative;
}

.itemImage {
    width: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 246px;
}

.showDialog {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
}