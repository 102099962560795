.butMenu {
    display: flex;
    gap: 40px;
    font-size: 26;
    text-decoration: underline;
    cursor: pointer;
}

.subMenu {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 10px 20px;
}

.padding20 {
    padding: 0px 20px;
}

.headerSection {
    margin-Top: 85px;
    padding: 0px 20px;
}

.campaignBody {
    display: flex;
    gap: 10px;
    margin-Top: 50px;
    margin-Bottom: 10px;
    justify-Content: space-between;
}

.campaignSection {
    gap: 10px;
    font-Family: HillBold;
    font-Size: 16px;
    color: #133f26;
}