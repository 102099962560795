.buttonStyle {
    position: absolute;
    top: 50%;
    cursor: pointer;
    user-Select: none;
}

.imageSlider{
    width: 700px;
    height: 675px;
    background-Size: contain;
    background-Repeat: no-repeat;
    background-Position: center;
}