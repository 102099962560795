.loginImage {
    padding: 15px;
    text-Align: left;
}

.loginBox {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginText {
    color: #ffffff;
    margin-Bottom: 20px;
    font-Size: 29px;
    font-Family: HillBold;
}

.loginForm {
    width: 300px;
}

.inputText {
    font-Family: HillRegular;
    font-Size: 21px;
}

.invalid {
    color: #ff0000;
    font-Size: 1.6rem;
    margin-Top: 20px;
    width: 300px;
}