.body {
    display: flex;
    align-items: center;
    justify-Content: center;
    gap: 10px;
    margin: 20px 20px 30px 20px;
    background-Color: #e9e9e9;
    height: 30px;
}

.linkStyle {
    font-Size: 16px;
    color: #133f26;
    padding: 5px;
    cursor: pointer;
}