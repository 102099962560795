#body {
    border-bottom: 1px solid #e9e9e9;
    padding: 0px 20px 10px 20px;
}
.subBody {
    display: flex;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.sectionStyle {
    display: flex;
    gap: 10px;
    font-family: "HillBold";
    font-size: 16px;
}

.editButton {
    text-Decoration: underline;
    font-Size: 16;
    cursor: pointer;
}