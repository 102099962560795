.title {
    font-Family: HillBold !important;
        font-Size: 22px !important;
        padding: 40px 40px 0px 40px !important;
}

.dialogContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-Column-Gap: 10px
}

.dialogButtons {
    font-Family: HillRegular;
    font-Size: 17px;
    color: #FF0000;
    margin-Left: 24px;
}

.confirmStyle {
    width: 300px;
    min-Height: 250px;
    display: flex;
    align-Items: center;
    justify-Content: center;
    padding: 40px;
}

.confirmTextStyle {
    font-Family: HillBold;
    font-Size: 22px;
    margin-Bottom: 30;
    text-Align: center;
}

.confirmTextButtons {
    margin-Top: 30;
    display: flex;
    gap: 10px;
}

.input {
    font-Family: HillRegular;
    font-Size: 16px;
}

.padding020 {
    padding: 0px 20px 20px 20px;
}

.padding20 {
    padding: 20px 20px 20px 20px;
}

.marginTop30 {
    margin-Top: 30px;
}

.borderBottom {
    border-Bottom: 1px solid #e9e9e9;
}

.previewSection {
    font-family: HillBold;
    font-Size: 22px;
    margin-Right: 30px;
}

.previewFilename {
    font-Family: HillRegular;
    font-Size: 16px;
}

.previewCommentBody {
    width: 300px;
    min-Height: 250px;
    display: flex;
    align-Items: center;
    justify-Content: center;
    padding: 40px;
}

.previewCommentTitle {
    font-Family: HillBold;
    font-Size: 22px;
    margin-Bottom: 30px;
    text-Align: center;
}

.previewCommentText {
    text-align: center;
    font-size: 16px;
}

.previewCommentButtonBody {
    display: flex;
    align-items: center;
    justify-Content: center;
}

.uploadFileBody {
    font-Family: HillBold;
    font-Size: 22px;
    padding: 40px 40px 0px 40px;
}

.uploadInputFields {
    display: grid;
    grid-Template-Columns: 1fr 1fr;
    grid-Column-Gap: 10px;
    margin-Bottom: 20px;
    height: 300px;
    margin-Top: 20px;
}



