.body {
    margin: 10px;
    font-Size: 16px;
}

.bodyItem {
    padding-Bottom: 20px;
    padding-Top: 10px;
    font-family: HillRegular;
}
.sideMenuBox {
    text-Align: left;
    padding: 10px;
}
.minHeightOnMenu {
    min-Height: 60px;
}