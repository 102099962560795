.headerTag {
    background-Color: #ff8862;
    padding: 5px 8px;
    color: #ffffff;
    border-Radius: 15px;
    font-Size: 16px;
}

.tagStyle {
    display: flex;
    gap: 10px;
    margin-Top: 20px;
}