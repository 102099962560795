.sideMenuBody {
    position: fixed;
    bottom: 0px;
    width: 320px;
    height: 50px;
    color: #ffffff;
}

.buttons {
    color: #ffffff !important;
        font-Size: 22px !important;
        font-Family: HillBold !important;
        width: 320px !important;
        height: 50px !important;
        text-Transform: none !important;
}

.appBar {
    background-Color: #133f26;
    z-Index: 22px;
    height: 60px;
}

.logout {
    font-Size: 16px;
    font-Family: HillBold;
    cursor: pointer;
}